import React from "react"

export const Footer = props => {
    const currentYear = new Date().getFullYear()
    return (
        <footer id="footer" className="footer" aria-label="Site Footer">

            <div className="bottom-bar">
                <div className="centre-view">
                    <ul className="flex justify-space-between m-justify-center">

                        <li className="smallprint flex m-justify-center">
                            <span>&copy;2020-{currentYear} Griffin Web Studio Limited. All Rights Reserved.</span>
                        </li>

                        <li className="design-by-gws">
                            <a title="Griffin Studio Limited" href="http://www.griffin-web.studio">
                                <span>Design by</span>
                                <img src="https://files.gwssecureserver.co.uk/files/gws/logo-white.svg" width="50" alt="Site Logo" aria-label="Site Logo" />
                            </a>
                        </li>

                    </ul>
                </div>
            </div>

        </footer>
    )
}