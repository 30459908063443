import React from "react";
import { Header } from "./components/sections/header";
import { Footer } from "./components/sections/footer";
import { MouseLayer } from "./components/MouseLayer";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/pages/Home";
import { Styleguide } from "./components/pages/Styleguide";

function App() {
    return (
        <>
            <MouseLayer />
            
            <Header />

            <main id="page-content" className="main" aria-label="Main Content">
                <div className="main-inner">

                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/styleguide" element={<Styleguide />} />
                    </Routes>

                </div>
            </main>

            <Footer />
        </>
    );
}

export default App;
