const scrollToViewHandler = (event) => {
    event.preventDefault();
    const scrollPosition = event.target.getAttribute("scrollPosition") ? event.target.getAttribute("scrollPosition") : null;
    const href = event.target.getAttribute("href");
    const match = href.match(/#(.*)/);

    if (!match) {
        return;
    }

    const id = match[1].split("?")[0];
    const element = document.getElementById(id);

    if (element) {
        element.scrollIntoView({behavior: "smooth", block: scrollPosition});
    }
};

export { scrollToViewHandler };
