import { Link } from "react-router-dom";
import React from "react";
import Flickity from "react-flickity-component";
import { scrollToViewHandler } from "../../lib/scroll";
import { Tabs } from "../common/Tabs";
import { Accordion } from "../common/Accordion";

export const Styleguide = (props) => {
    const flickityDefaults = {
        accessibility: false,
        cellAlign: "center",
        contain: true,
        draggable: ">1",
        autoPlay: 5000,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        percentPosition: true,
        initialIndex: 1
    };

    return (
        <article id="wrapper">
            <section className="slideshow-examples">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="screen-reader-text">Flickity</h2>

                        <article className="article-content">
                            <h2 className="section-title">Flickity</h2>

                            <section id="slideshow__single">
                                <Flickity className="slideshow" elementType={"div"} options={{ ...flickityDefaults, autoPlay: 2000 }} disableImagesLoaded={false} reloadOnUpdate static={false}>
                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-13)" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Single Slide Title 1</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>

                                                <p>
                                                    Cras nec purus orci. Donec sed ipsum ipsum. Morbi ut condimentum eros, et tempus urna. Nulla at commodo metus. Ut vestibulum tincidunt nisi et rutrum.Pellentesque faucibus nunc eu mi feugiat cursus.
                                                    Morbi non pretium eros, id posuere urna.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-12)" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Single Slide Title 2</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>

                                                <p>
                                                    Cras nec purus orci. Donec sed ipsum ipsum. Morbi ut condimentum eros, et tempus urna. Nulla at commodo metus. Ut vestibulum tincidunt nisi et rutrum.Pellentesque faucibus nunc eu mi feugiat cursus.
                                                    Morbi non pretium eros, id posuere urna.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Flickity>
                            </section>

                            <section id="slideshow__carousel">
                                <Flickity className="slideshow carousel" elementType={"div"} options={{ ...flickityDefaults, groupCells: 1, autoPlay: 1000, cellAlign: "left" }} disableImagesLoaded={false} reloadOnUpdate static={false}>
                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-13)", width: "33.33%", padding: "1rem" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Carousel Slide Title 1</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-12)", width: "33.33%", padding: "1rem" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Carousel Slide Title 2</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-13)", width: "33.33%", padding: "1rem" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Carousel Slide Title 3</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slideshow-slide" style={{ backgroundColor: "var(--pref-color-12)", width: "33.33%", padding: "1rem" }}>
                                        <div className="centre-view">
                                            <h2 className="slide-title">Carousel Slide Title 4</h2>

                                            <div className="slide-content">
                                                <h3 className="slide-content-title">Slide Content title</h3>

                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus nulla id tortor fringilla, ut tristique nulla consequat. Cras tincidunt eros etfinibus sodales. Sed suscipit sem leo, vel
                                                    scelerisque nisl pellentesque a. Sed aliquam, nibh nec tempor eleifend, ante enim tristique turpis, vel blanditmetus ante et enim. Morbi lobortis nec erat nec molestie. Quisque commodo velit eget
                                                    magna porta eleifend. Fusce vel blandit nisi. Sed varius, turpis idpretium aliquet, nisl arcu dapibus ipsum, quis pulvinar orci mauris vel magna. Mauris est erat, vulputate at cursus tempor,
                                                    suscipit vel erat. Phasellusmattis libero sit amet erat porttitor, id mattis massa accumsan. Morbi vel dolor venenatis, semper felis eu, elementum libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Flickity>
                            </section>

                            <footer>
                                <p>
                                    <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                        back to top
                                    </Link>
                                </p>
                            </footer>
                        </article>
                    </div>
                </div>
            </section>

            <section id="tabs" className="tabs">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="section-title">Tabs</h2>

                        <Tabs
                            content={[
                                {
                                    key: "tab-1",
                                    label: "Tab 1",
                                    content: <p>Tab 1 content</p>
                                },
                                {
                                    key: "tab-2",
                                    label: "Tab 2",
                                    content: <p>Tab 2 content</p>
                                },
                                {
                                    key: "tab-3",
                                    label: "Tab 3",
                                    content: <p>Tab 3 content</p>
                                }
                            ]}
                        />

                        <footer>
                            <p>
                                <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                    back to top
                                </Link>
                            </p>
                        </footer>
                    </div>
                </div>
            </section>

            <section id="accordion" className="accordion">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="section-title">Accordion</h2>

                        <Accordion
                            content={[
                                {
                                    key: "accordion-1",
                                    label: "Accordion Fold 1",
                                    content: (
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                            voluptas quos, consequuntur, laborum nulla.
                                        </p>
                                    )
                                },
                                {
                                    key: "accordion-2",
                                    label: "Accordion Fold 2",
                                    content: (
                                        <>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>
                                        </>
                                    )
                                },
                                {
                                    key: "accordion-3",
                                    label: "Accordion Fold 3",
                                    content: (
                                        <>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>
                                        </>
                                    )
                                },
                                {
                                    key: "accordion-4",
                                    label: "Accordion Fold 4",
                                    content: (
                                        <>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis enim exercitationem incidunt optio ducimus tempore ab possimus eligendi mollitia eos voluptates asperiores accusantium veritatis
                                                voluptas quos, consequuntur, laborum nulla.
                                            </p>
                                        </>
                                    )
                                }
                            ]}
                        />

                        <footer>
                            <p>
                                <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                    back to top
                                </Link>
                            </p>
                        </footer>
                    </div>
                </div>
            </section>

            <section id="html-primitive-elemets" className="html-primetive-elements">
                <div className="centre-view">
                    <div className="section-content">
                        {/* I) All Title tags (except for the ones generated by the editor) should have classes starting with defenition of parent section

                                e.g section-title, slide-title, article-title, etc.



                                II) Also Rememeber that section tag should contain some heading so in this case the article heading is outside of article tag but still is called article-title

                                this is a single exception where if no titles are availible in the section you should use the title of a sibling (but not if it's a list item)

                                In case no titles are availible you should consider maing an Screen Reader, SEO and crawler fiendly .screen-reader-text title */}

                        <h2 className="section-title screen-reader-text">HTML Primitive Elements</h2>

                        <article className="article-content">
                            <h2 className="article-title">HTML Primitive Elements</h2>

                            <p>An HTML element is defined by a start tag, some content, and an end tag.</p>

                            <section id="text__headings">
                                <h3 className="article-content-heading">Heading(s)</h3>

                                <p>HTML headings are titles or subtitles that you want to display on a webpage.</p>

                                {/* Sometimes designs may not follow the natural heading flow and jump between font sizes,

                                        in those cases continue to follow the heading flow but override the size or style using classes */}

                                <div>
                                    <h2 className="h1 top-heading">Heading 1</h2>

                                    <h2>Heading 2</h2>

                                    <h3>Heading 3</h3>

                                    <h4>Heading 4</h4>

                                    <h5>Heading 5</h5>

                                    <h6>Heading 6</h6>
                                </div>

                                <hr />
                            </section>

                            {/* Sections don't nececerary have to be used for as blocks, sometimes it's a lot more "readibility friendly" to add them to make the contentn visually seperated.

                                    Do still remember thatsection tags should be within the article and follow the semantic element flow */}

                            <section id="text__paragraph">
                                <h3 className="article-content-title">Paragraph(s)</h3>

                                {/* Paragraph tag should ALMOST NEVER have any classes but inherit from the parent wrapper tag in this case article-content */}

                                <p>
                                    A paragraph always starts on a new line, and browsers automatically add some white space (a margin) before and after a paragraph. <b>By The Way (bold)</b>, this <u>is (underline)</u> a <i>paragraph (italic)</i>
                                </p>

                                <p>
                                    Lorem ipsum dolor sit amet,{" "}
                                    <a href="https://griffin-web.studio" title="test link">
                                        test link (goes to Griffin Web Studio Website)
                                    </a>{" "}
                                    adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent
                                    mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. Mauris a ante. Suspendisse quam sem,
                                    consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.
                                </p>

                                <hr />
                            </section>

                            <section id="text__inline">
                                <h3 className="article-content-title">Inline element(s)</h3>

                                <div>
                                    <p>
                                        <a href="#!">This is a text link</a>.
                                    </p>

                                    <p>
                                        <strong>Strong is used to indicate strong importance.</strong>
                                    </p>

                                    <p>
                                        <em>This text has added emphasis.</em>
                                    </p>

                                    <p>
                                        The <b>b element</b> is stylistically different text from normal text, without any special importance.
                                    </p>

                                    <p>
                                        The <i>i element</i> is text that is offset from the normal text.
                                    </p>

                                    <p>
                                        The <u>u element</u> is text with an unarticulated, though explicitly rendered, non-textual annotation.
                                    </p>

                                    <p>
                                        <del>This text is deleted</del> and <ins>This text is inserted</ins>.
                                    </p>

                                    <p>
                                        <s>This text has a strikethrough</s>.
                                    </p>

                                    <p>
                                        Superscript<sup>®</sup>.
                                    </p>

                                    <p>
                                        Subscript for things like H<sub>2</sub>O.
                                    </p>

                                    <p>
                                        <small>This small text is small for for fine print, etc.</small>
                                    </p>

                                    <p>
                                        Abbreviation: <abbr title="HyperText Markup Language">HTML</abbr>
                                    </p>

                                    <p>
                                        <q cite="https://developer.mozilla.org/en-US/docs/HTML/Element/q">This text is a short inline quotation.</q>
                                    </p>

                                    <p>
                                        <cite>This is a citation.</cite>
                                    </p>

                                    <p>
                                        The <dfn>dfn element</dfn> indicates a definition.
                                    </p>

                                    <p>
                                        The <mark>mark element</mark> indicates a highlight.
                                    </p>

                                    <p>
                                        The <var>variable element</var>, such as <var>x</var> = <var>y</var>.
                                    </p>

                                    <p>
                                        The time element: <time datetime="2013-04-06T12:32+00:00">2 weeks ago</time>
                                    </p>
                                </div>

                                <hr />
                            </section>

                            <section id="text__blockquote">
                                <h3 className="article-content-title">blackquote(s)</h3>

                                <div>
                                    <blockquote>
                                        <p>A block quotation (also known as a long quotation or extract) is a quotation in a written document, that is set off from the main text as a paragraph, or block of text.</p>

                                        <p>It is typically distinguished visually using indentation and a different typeface or smaller size quotation. It may or may not include a citation, usually placed at the bottom.</p>

                                        <cite>
                                            <a href="#!">Said no one, ever.</a>
                                        </cite>
                                    </blockquote>
                                </div>

                                <hr />
                            </section>

                            <section id="text__list">
                                <h3 className="article-content-heading">List Type(s)</h3>

                                <p>HTML lists allow web developers to group a set of related items in lists.</p>

                                {/* Sometimes designs may not follow the natural heading flow and jump between font sizes,

                                        in those cases continue to follow the heading flow but override the size or style using classes */}

                                <h4>Definition List (Defines a description list)</h4>

                                <dl>
                                    <dt>Defines a term in a description list</dt>

                                    <dd>Describes the term in a description list</dd>
                                </dl>

                                <h4>Ordered List</h4>

                                <ol type="1">
                                    <li>List Item 1</li>

                                    <li>List Item 2</li>

                                    <li>
                                        List Item 3
                                        <ol type="A">
                                            <li>Sub List Item 1</li>

                                            <li>Sub List Item 2</li>

                                            <li>
                                                Sub List Item 3
                                                <ol type="a">
                                                    <li>Sub List Item 1</li>

                                                    <li>Sub List Item 2</li>

                                                    <li>
                                                        Sub List Item 3
                                                        <ol type="I">
                                                            <li>Sub List Item 1</li>

                                                            <li>Sub List Item 2</li>

                                                            <li>
                                                                Sub List Item 3
                                                                <ol type="i">
                                                                    <li>Sub List Item 1</li>

                                                                    <li>Sub List Item 2</li>

                                                                    <li>Sub List Item 3</li>
                                                                </ol>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <h4>Unordered List</h4>

                                <ul>
                                    <li>List Item 1</li>

                                    <li>List Item 2</li>

                                    <li>
                                        List Item 3
                                        <ul>
                                            <li>Sub List Item 1</li>

                                            <li>Sub List Item 2</li>

                                            <li>
                                                Sub List Item 3
                                                <ul>
                                                    <li>Sub List Item 1</li>

                                                    <li>Sub List Item 2</li>

                                                    <li>Sub List Item 3</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <h4>Mixed List</h4>

                                <ol>
                                    <li>List Item 1</li>

                                    <li>List Item 2</li>

                                    <li>
                                        List Item 3
                                        <ul>
                                            <li>Sub List Item 1</li>

                                            <li>Sub List Item 2</li>

                                            <li>
                                                Sub List Item 3
                                                <ol>
                                                    <li>Sub List Item 1</li>

                                                    <li>Sub List Item 2</li>

                                                    <li>Sub List Item 3</li>
                                                </ol>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>

                                <hr />
                            </section>

                            <section id="text__table">
                                <h3 className="article-content-heading">Fieldset, Legend, and Form Element(s)</h3>

                                <p>
                                    The <code>&lt;Fieldset&gt;</code> tag is used to group related elements in a<code>&lt;form&gt;</code>, it also draws a box around the related elements.
                                </p>

                                <p>
                                    The <code>&lt;legend&gt;</code> tag defines a caption for the <code>&lt;fieldset&gt;</code>
                                    element.
                                </p>

                                <p>
                                    The <code>&lt;form&gt;</code> tag is used to create an HTML form for user input, it can contain one or more of the following form elements:
                                </p>

                                <ul>
                                    <li>
                                        <code>&lt;input&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;textarea&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;button&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;select&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;option&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;optgroup&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;fieldset&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;label&gt;</code>
                                    </li>

                                    <li>
                                        <code>&lt;output&gt;</code>
                                    </li>
                                </ul>

                                <form>
                                    <fieldset id="forms__input">
                                        <legend>Input fields</legend>

                                        <p>
                                            <label for="input__text">Text Input</label>

                                            <input id="input__text" type="text" placeholder="Text Input" />
                                        </p>

                                        <p>
                                            <label for="input__password">Password</label>

                                            <input id="input__password" type="password" placeholder="Type your Password" />
                                        </p>

                                        <p>
                                            <label for="input__webaddress">Web Address</label>

                                            <input id="input__webaddress" type="url" placeholder="http://yoursite.com" />
                                        </p>

                                        <p>
                                            <label for="input__emailaddress">Email Address</label>

                                            <input id="input__emailaddress" type="email" placeholder="name@email.com" />
                                        </p>

                                        <p>
                                            <label for="input__phone">Phone Number</label>

                                            <input id="input__phone" type="tel" placeholder="(999) 999-9999" />
                                        </p>

                                        <p>
                                            <label for="input__search">Search</label>

                                            <input id="input__search" type="search" placeholder="Enter Search Term" />
                                        </p>

                                        <p>
                                            <label for="input__text2">Number Input</label>

                                            <input id="input__text2" type="number" placeholder="Enter a Number" />
                                        </p>

                                        <p>
                                            <label for="input__text3" className="error">
                                                Error
                                            </label>

                                            <input id="input__text3" className="is-error" type="text" placeholder="Text Input" />
                                        </p>

                                        <p>
                                            <label for="input__text4" className="valid">
                                                Valid
                                            </label>

                                            <input id="input__text4" className="is-valid" type="text" placeholder="Text Input" />
                                        </p>
                                    </fieldset>

                                    <fieldset id="forms__select">
                                        <legend>Select menus</legend>

                                        <p>
                                            <label for="select">Select</label>

                                            <select id="select">
                                                <optgroup label="Option Group">
                                                    <option>Option One</option>

                                                    <option>Option Two</option>

                                                    <option>Option Three</option>
                                                </optgroup>
                                            </select>
                                        </p>
                                    </fieldset>

                                    <fieldset id="forms__checkbox">
                                        <legend>Checkboxes</legend>

                                        <ul className="list list--bare">
                                            <li>
                                                <label for="checkbox1">Choice A</label>

                                                <input id="checkbox1" name="checkbox" type="checkbox" defaultChecked />
                                            </li>

                                            <li>
                                                <label for="checkbox2">Choice B</label>

                                                <input id="checkbox2" name="checkbox" type="checkbox" />
                                            </li>

                                            <li>
                                                <label for="checkbox3">Choice C</label>

                                                <input id="checkbox3" name="checkbox" type="checkbox" />
                                            </li>
                                        </ul>
                                    </fieldset>

                                    <fieldset id="forms__radio">
                                        <legend>Radio buttons</legend>

                                        <ul className="list list--bare">
                                            <li>
                                                <label for="radio1">Option 1</label>

                                                <input id="radio1" name="radio" type="radio" className="radio" defaultChecked />
                                            </li>

                                            <li>
                                                <label for="radio2">Option 2</label>

                                                <input id="radio2" name="radio" type="radio" className="radio" />
                                            </li>

                                            <li>
                                                <label for="radio3">Option 3</label>

                                                <input id="radio3" name="radio" type="radio" className="radio" />
                                            </li>
                                        </ul>
                                    </fieldset>

                                    <fieldset id="forms__textareas">
                                        <legend>Textareas</legend>

                                        <p>
                                            <label for="textarea">Textarea</label>

                                            <textarea id="textarea" rows="8" cols="48" placeholder="Enter your message here"></textarea>
                                        </p>
                                    </fieldset>

                                    <fieldset id="forms__html5">
                                        <legend>HTML5 inputs</legend>

                                        <p>
                                            <label for="ic">Color input</label>

                                            <input type="color" id="ic" value="#000000" />
                                        </p>

                                        <p>
                                            <label for="in">Number input</label>

                                            <input type="number" id="in" min="0" max="10" value="5" />
                                        </p>

                                        <p>
                                            <label for="ir">Range input</label>

                                            <input type="range" id="ir" value="10" />
                                        </p>

                                        <p>
                                            <label for="idd">Date input</label>

                                            <input type="date" id="idd" value="1970-01-01" />
                                        </p>

                                        <p>
                                            <label for="idm">Month input</label>

                                            <input type="month" id="idm" value="1970-01" />
                                        </p>

                                        <p>
                                            <label for="idw">Week input</label>

                                            <input type="week" id="idw" value="1970-W01" />
                                        </p>

                                        <p>
                                            <label for="idt">Datetime input</label>

                                            {/* Note: input elements with type="datetime" do not show as any datetime field/calendar in any of the major browsers, except Safari. */}

                                            <input type="datetime" id="idt" value="1970-01-01T00:00:00Z" />
                                        </p>

                                        <p>
                                            <label for="idtl">Datetime-local input</label>

                                            <input type="datetime-local" id="idtl" value="1970-01-01T00:00" />
                                        </p>
                                    </fieldset>

                                    <fieldset id="forms__action">
                                        <legend>Action buttons</legend>

                                        <p>
                                            <input type="submit" value="input submit" />

                                            <input type="button" value="input button" />

                                            <input type="reset" value="input reset" />

                                            <input type="submit" value="input disabled" disabled />
                                        </p>

                                        <p>
                                            <button type="submit">button submit</button>

                                            <button type="button">button button</button>

                                            <button type="reset">button reset</button>

                                            <button type="button" disabled>
                                                button disabled
                                            </button>
                                        </p>
                                    </fieldset>

                                    <hr />
                                </form>
                            </section>

                            <section id="tables">
                                <h3 className="section-title">Table(s)</h3>

                                <p>HTML tables allow web developers to arrange data into rows and columns.</p>

                                <table>
                                    <caption>Table Caption</caption>

                                    <thead>
                                        <tr>
                                            <th>Table Heading 1</th>

                                            <th>Table Heading 2</th>

                                            <th>Table Heading 3</th>

                                            <th>Table Heading 4</th>

                                            <th>Table Heading 5</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Table Cell 1</td>

                                            <td>Table Cell 2</td>

                                            <td>Table Cell 3</td>

                                            <td>Table Cell 4</td>

                                            <td>Table Cell 5</td>
                                        </tr>

                                        <tr>
                                            <td>Table Cell 1</td>

                                            <td>Table Cell 2</td>

                                            <td>Table Cell 3</td>

                                            <td>Table Cell 4</td>

                                            <td>Table Cell 5</td>
                                        </tr>

                                        <tr>
                                            <td>Table Cell 1</td>

                                            <td>Table Cell 2</td>

                                            <td>Table Cell 3</td>

                                            <td>Table Cell 4</td>

                                            <td>Table Cell 5</td>
                                        </tr>

                                        <tr>
                                            <td>Table Cell 1</td>

                                            <td>Table Cell 2</td>

                                            <td>Table Cell 3</td>

                                            <td>Table Cell 4</td>

                                            <td>Table Cell 5</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th>Table Footer 1</th>

                                            <th>Table Footer 2</th>

                                            <th>Table Footer 3</th>

                                            <th>Table Footer 4</th>

                                            <th>Table Footer 5</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </section>

                            <footer>
                                <p>
                                    <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                        back to top
                                    </Link>
                                </p>
                            </footer>
                        </article>
                    </div>
                </div>
            </section>

            <section id="text__code">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="screen-reader-text">Code</h2>

                        <article className="article-content">
                            <h2 className="section-title">Code</h2>

                            <section>
                                <h3 className="section-title">Preformated Text</h3>

                                <p>
                                    <strong>Keyboard input:</strong> <kbd>CTRL</kbd> + <kbd>C</kbd>
                                </p>

                                <p>
                                    <strong>Inline code:</strong> <code>&lt;div&gt;code&lt;/div&gt;</code>
                                </p>

                                <p>
                                    <strong>Sample output:</strong> <samp>This is sample output from a computer program.</samp>
                                </p>

                                <p>
                                    The <code>&lt;pre&gt;</code> tag defines preformatted text. Text in a <code>&lt;pre&gt;</code> element is displayed in a fixed-width font, and the text preserves both spaces and line breaks. The text will be
                                    displayed exactly as written in the HTML source code.
                                </p>

                                <pre>
                                    P R E F O R M A T T E D T E X T<br />! " # $ % &amp; ' ( ) * + , - . /<br />0 1 2 3 4 5 6 7 8 9 : ; &lt; = &gt; ?<br />@ A B C D E F G H I J K L M N O<br />P Q R S T U V W X Y Z [ \ ] ^ _<br />` a b c d e f g h i j
                                    k l m n o<br />p q r s t u v w x y z & {"{ | }"} ~{" "}
                                </pre>
                            </section>

                            <footer>
                                <p>
                                    <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                        back to top
                                    </Link>
                                </p>
                            </footer>
                        </article>
                    </div>
                </div>
            </section>

            <section id="text__comments">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="section-title">HTML Comments</h2>

                        <div>
                            <p>
                                There is comment here:
                                {/* This comment should not be displayed */}
                            </p>

                            <p>There is a comment spanning multiple tags and lines below here.</p>

                            {/* <p><a href="#!">This is a text link. But it should not be displayed in a comment</a>.</p>

                            <p><strong>Strong is used to indicate strong importance. But, it should not be displayed in a comment</strong></p>

                            <p><em>This text has added emphasis. But, it should not be displayed in a comment</em></p> */}
                        </div>

                        <footer>
                            <p>
                                <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                    back to top
                                </Link>
                            </p>
                        </footer>
                    </div>
                </div>
            </section>

            <section id="embedded">
                <div className="centre-view">
                    <div className="section-content">
                        <h2 className="section-title">Embedded content</h2>

                        <article id="embedded__images" className="article-content">
                            <header>
                                <h3 className="article-content">Images</h3>
                            </header>

                            <div>
                                <h4>
                                    No <code>&lt;figure&gt;</code> element
                                </h4>

                                <p>
                                    <img src="http://placekitten.com/480/480" alt="alt text" />
                                </p>

                                <h4>
                                    Wrapped in a <code>&lt;figure&gt;</code> element, no <code>&lt;figcaption&gt;</code>
                                </h4>

                                <figure>
                                    <img src="http://placekitten.com/420/420" alt="alt text" />
                                </figure>

                                <h4>
                                    Wrapped in a <code>&lt;figure&gt;</code> element, with a <code>&lt;figcaption&gt;</code>
                                </h4>

                                <figure>
                                    <img src="http://placekitten.com/420/420" alt="alt text" />

                                    <figcaption>Here is a caption for this image.</figcaption>
                                </figure>
                            </div>
                        </article>

                        <article id="embedded__audio" className="article-content">
                            <header>
                                <h3 className="article-title">Audio</h3>
                            </header>

                            <div>
                                <audio controls="">audio</audio>
                            </div>
                        </article>

                        <article id="embedded__video" className="article-content">
                            <header>
                                <h3 className="article-title">Video</h3>
                            </header>

                            <div>
                                <video controls="">video</video>
                            </div>
                        </article>

                        <article id="embedded__canvas" className="article-content">
                            <header>
                                <h3 className="article-title">Canvas</h3>
                            </header>

                            <div>
                                <canvas>canvas</canvas>
                            </div>
                        </article>

                        <article id="embedded__meter" className="article-content">
                            <header>
                                <h3 className="article-title">Meter</h3>
                            </header>

                            <div>
                                <meter value="2" min="0" max="10">
                                    2 out of 10
                                </meter>
                            </div>
                        </article>

                        <article id="embedded__progress" className="article-content">
                            <header>
                                <h3 className="article-title">Progress</h3>
                            </header>

                            <div>
                                <progress>progress</progress>
                            </div>
                        </article>

                        <article id="embedded__svg" className="article-content">
                            <header>
                                <h3 className="article-title">Inline SVG</h3>
                            </header>

                            <div>
                                <svg width="100px" height="100px">
                                    <circle cx="100" cy="100" r="100" fill="#1fa3ec"></circle>
                                </svg>
                            </div>
                        </article>

                        <article id="embedded__iframe" className="article-content">
                            <header>
                                <h3 className="article-title">IFrame</h3>
                            </header>

                            <div>
                                <iframe src="index.html" height="300" title="test iframe"></iframe>
                            </div>
                        </article>

                        <footer>
                            <p>
                                <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                                    back to top
                                </Link>
                            </p>
                        </footer>
                    </div>
                </div>
            </section>
        </article>
    );
};
