import React, { useState } from "react";

export const Tabs = (props) => {
    const { content } = props;
    const [activeTab, setActiveTab] = useState();

    const onTabLinkClickHandler = (key) => {
        setActiveTab(key);
    };

    return (
        <div className="tabs">
            <ul className="tabs-nav">
                {content.map((item) => {
                    return(
                        <li key={item.key}>
                            <button className={`tabs-link${activeTab === item.key ? ' is-active' : '' }`} onClick={() => {onTabLinkClickHandler(item.key)}}>
                                {item.label}
                            </button>
                        </li>
                    )
                })}
            </ul>

            {content.map((item) => {
                return(
                    <div key={item.key} className={`tab-content${activeTab === item.key ? ' is-active' : '' }`} onClick={() => {onTabLinkClickHandler(item.key)}}>
                        <h3 className="tab-title">{item.label}</h3>
                        <div className="tab-content__content">
                            {item.content}
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
