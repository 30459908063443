import React, { useState } from "react";

export const Accordion = (props) => {
    const { content } = props;
    const [activeAccordion, setActiveAccordion] = useState();

    const onAccordionLinkClickHandler = (key) => {
        if (activeAccordion === key) {
            setActiveAccordion();
            return;
        }
        setActiveAccordion(key);
    };

    return (
        <div className="accordion">
            {content.map((item) => {
                return(
                    <div key={item.key} className={`accordion-group${activeAccordion === item.key ? ' is-active' : '' }`}>
                        <div className="accordion-title">
                            <button className="accordion-button" onClick={() => {onAccordionLinkClickHandler(item.key)}}>
                                {item.label}
                            </button>
                        </div>

                        <div className="accordion-panel">
                            <div className="accordion-panel-inner">
                                {item.content}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
