import React, { useState, useEffect, useRef } from "react";

let gwsDebug = false;

let cursorLockType = ["base"];
const textTags = [
        "pre",
        "p",
        "span",
        "caption",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "th",
        "td",
        "textarea",
        "input[type=tel]",
        "input[type=text]",
        "input[type=time]",
        "input[type=url]",
        "input[type=week]",
        "input[type=search]",
        "input[type=month]",
        "input[type=number]",
        "input[type=password]",
        "input[type=date]",
        "input[type=datetime-local]",
        "input[type=email]"
    ],
    linkTags = ["a", "button", "input[type=button]", "input[type=color]", "input[type=file]", "input[type=image]", "input[type=radio]", "input[type=range]", "input[type=reset]", "input[type=submit]"]; /*,
choiceTags = [
    '.dropdown',
    '.control--checkbox',
    '.control__indicator',
    'select',
    'input[type=radio]',
    'input[type=checkbox]',
    'input[type=select]',
    'input[disabled]'
]*/
export const MouseLayer = (props) => {
    const [mousePosition, setMousePosition] = useState({ x: "50vw", y: "50vh" });
    const [pointerInViewport, setPointerInViewport] = useState(true);
    const smallCursor = useRef(null);
    const largeCursor = useRef(null);

    useEffect(() => {
        function handleMouseMove(event) {
            setPointerInViewport(true);

            if (pointerInViewport) {
                setMousePosition({ x: event.clientX, y: event.clientY });

                if (!cursorLockType.includes("link") && cursorLockType.includes("text")) {
                    smallCursor.current.classList.add("on-screen");
                    largeCursor.current.removeAttribute("class");
                    return;
                } else if (cursorLockType.includes("link")) {
                    return;
                }

                smallCursor.current.classList.add("on-screen");
                largeCursor.current.classList.add("on-screen");
            } else {
                smallCursor.current.removeAttribute("class");
                largeCursor.current.removeAttribute("class");
            }
        }

        window.addEventListener("mousemove", handleMouseMove);

        document.body.addEventListener("mouseout", () => {
            setPointerInViewport(false);
            
            if (!cursorLockType.includes("link")) {
                cursorLockType = ["base"];
                smallCursor.current.removeAttribute("class");
                largeCursor.current.removeAttribute("class");
            }
        });

        document.querySelectorAll(linkTags.join()).forEach((el) => {
            el.addEventListener("mouseenter", () => {
                if (!cursorLockType.includes("link")) {
                    cursorLockType.push("link");
                    smallCursor.current.classList.add("active");
                    largeCursor.current.removeAttribute("class");
                }
            });
            el.addEventListener("mouseleave", () => {
                cursorLockType = cursorLockType.filter((item) => item !== "link");
                smallCursor.current.removeAttribute("class");
                largeCursor.current.removeAttribute("class");
                smallCursor.current.classList.add("on-screen");
                largeCursor.current.classList.add("on-screen");
            });
        });

        document.querySelectorAll(textTags.join()).forEach((el) => {
            el.addEventListener("mouseenter", () => {
                if (!cursorLockType.includes("link") && !cursorLockType.includes("text")) {
                    cursorLockType.push("text");
                    smallCursor.current.classList.add("text");
                    largeCursor.current.removeAttribute("class");
                }
            });
            el.addEventListener("mousemove", () => {
                if (cursorLockType.includes("link") && cursorLockType.includes("text")) {
                    cursorLockType = cursorLockType.filter((item) => item !== "text");
                    smallCursor.current.classList.remove("text");
                }
            });
            el.addEventListener("mouseleave", () => {
                cursorLockType = cursorLockType.filter((item) => item !== "text");
                if (cursorLockType.includes("text")) {
                    smallCursor.current.classList.remove("text");
                }
            });
        });

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [smallCursor, largeCursor, pointerInViewport]);

    return (
        <div className="cursor-container">
            <div id="js-cursor" className="custom-cursor" style={{ transform: `translate(${mousePosition.x + (mousePosition.x !== "50vw" ? "px" : "50vw")}, ${mousePosition.y + (mousePosition.y !== "50vh" ? "px" : "50vh")})` }}>
                <div ref={smallCursor} id="js-cursor__small"></div>
                <div ref={largeCursor} id="js-cursor__big"></div>
                {(process.env.NODE_ENV === 'development' || gwsDebug) && <div id="js-cursor__debug-dialogue">
                    <table>
                        <tbody style={{height: 'auto', backgroundColor: 'var(--pref-color-dark-0)', color: 'var(--pref-color-dark-100)'}}>
                            <tr>
                                <td>pointerInView</td>
                                <td>{pointerInViewport ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td>mouseX</td>
                                <td>{mousePosition.x}</td>
                            </tr>
                            <tr>
                                <td>mouseY</td>
                                <td>{mousePosition.y}</td>
                            </tr>
                            <tr>
                                <td>lockTrack</td>
                                <td>{cursorLockType.join(" > ")}</td>
                            </tr>
                            <tr>
                                <td>env</td>
                                <td>{process.env.NODE_ENV}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </div>
        </div>
    );
};
