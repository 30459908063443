import React from "react";

export const Home = () => {
    return (
        <article id="page-1" className="home article page type-page">

            <header className="section">
                <div className="section__outer bottom-right">
                    <div className="grid">
                        <div className="grid-24 grid-lt-12">
                            <div className="section__inner">
                                <div className="section__content">

                                    <div className="section__logo">
                                        <a href="https://griffin-web.studio" title="Back to Homepage">
                                            <img src="https://files.gwssecureserver.co.uk/files/gws/logo.svg" width="100" alt="Site Logo" aria-label="Site Logo" />
                                        </a>
                                    </div>

                                    {/* <h1>Nobility, Intelligence, Loyalty, Dedication</h1> */}

                                    <h1 className="section__heading">Welcome to Griffin Web Studio</h1>
                                    <h2 className="section__subheading">Web services made easy.</h2>

                                    <div className="section__copy">
                                        <p>At Griffin Web Studio, we specialize in bringing your online vision to life. Our comprehensive web services cover everything you need for a thriving digital presence. Whether you're a startup, small business, or enterprise, we've got you covered.</p>
                                        <p>
                                            It all starts with{" "}
                                            <span className="emoji">
                                                <span className="screen-reader-text">,</span>👋<span className="screen-reader-text">,</span>
                                            </span>{" "}
                                            <a href="mailto:hello@griffin-web.studio" title="hello@griffin-web.studio">
                                                HELLO@GRIFFIN-WEB.STUDIO
                                            </a>
                                            .
                                        </p>
                                    </div>

                                    <div className="section__accreditation">
                                        <p>
                                            <a className="ionos-partner-logo" href="https://partnernetwork.ionos.co.uk/partner/griffin-web.studio?origin=PartnerBadge" target="_blank" rel="noreferrer">
                                                <span>SEM | SEO | SEA | Domain Registration | Web Design | Website Maintenance | Branding | Graphic Design</span>

                                                <img
                                                    src="https://files.gwssecureserver.co.uk/files/email/v4/ionos.svg"
                                                    alt="IONOS - Agency Partner Select"
                                                />
                                            </a>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </article>
    );
};