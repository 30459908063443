import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToViewHandler } from '../../lib/scroll';

export const Header = props => {
    return (
        <>
            <div id="fader" className="fade-out">
                <div className="img-container">
                    <div className="img" style={{backgroundImage: "url('https://files.gwssecureserver.co.uk/files/gws/logo.svg')"}}>
                        <div className="screen-reader-text">Page Loading</div>
                    </div>
                </div>
            </div>
            
            <div id="skip-to-content" className="screen-reader-text">
                <Link to="#page-content" scrollPosition="start" onClick={scrollToViewHandler}>
                    Skip to main content
                </Link>
            </div>

            {/* <header id="site-header" className="site-header" aria-label="Main Header">
            
                <div className="header">
                    <div className="header-content">
                        
                        <content></content>
            
                    </div>
                </div>
            
            </header> */}
        </>
    )
}